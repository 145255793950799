import React, { useState, useEffect } from "react";
import { fabric } from "fabric";
import { useSelector } from "react-redux";
import moment from "moment";
import { useDispatch } from "react-redux";
import { showLoader, hideLoader } from "actions/loader";
import Select from "components/Select";
import Drawer from "@material-ui/core/Drawer";
import EditTextDrawer from "components/ArtWork/EditTextDrawer";
import TextField from "components/TextField";
import DeleteIcon from "@material-ui/icons/Delete";
import CropFreeIcon from "@material-ui/icons/CropFree";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import EditIcon from "@material-ui/icons/Edit";
import Tooltip from "@material-ui/core/Tooltip";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import VerticalAlignCenterIcon from "@material-ui/icons/VerticalAlignCenter";
import FormatAlignCenterIcon from "@material-ui/icons/FormatAlignCenter";
import VerticalAlignTopIcon from "@material-ui/icons/VerticalAlignTop";
import VerticalAlignBottomIcon from "@material-ui/icons/VerticalAlignBottom";
import StarBorderOutlined from "@material-ui/icons/StarBorderOutlined";
import KeyboardTabIcon from "@material-ui/icons/KeyboardTab";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ImportExportIcon from "@material-ui/icons/ImportExport";
import SyncAltIcon from "@material-ui/icons/SyncAlt";
import Chip from "@material-ui/core/Chip";
import Slider from "@material-ui/core/Slider";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import {
  LayersContainer,
  Header,
  CloseIconStyle,
  LayersWrapper,
  Layer,
  LayerActions,
  TextLayer,
  LayerCenter,
  AlignIcons,
  UpperLayer,
  Rotate,
} from "styles/components/ArtWork/browseLibrary";
import { getDTGPlusFromProductId } from "selectors/products";
import { Popover, Typography } from "@material-ui/core";

const onloadImageProcess = (src) => {
  return new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });
};

const LayersDrawer = ({
  open,
  toggle,
  canvas,
  onApply,
  printArea,
  plus,
  orderLine,
  setOrderLine,
}) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const products = useSelector((state) => state?.products?.data);
  const [canvasJSON, setCanvasJSON] = useState(canvas?.toJSON());
  const [dtgValues, setDtgOptions] = useState({});
  const [editTextDrawer, setEditTextDrawer] = useState(false);
  const [activeObjectIndex, setActiveObjectIndex] = useState(0);
  const [maskIndex, setMaskIndex] = useState(null);
  const printType = orderLine?.PrintType;

  useEffect(() => {
    if (!!canvas?.getObjects()?.length)
      canvas.setActiveObject(canvas?.getObjects()[activeObjectIndex]);
    canvas.requestRenderAll();
  }, [activeObjectIndex]);

  const dtgPlusOptions = getDTGPlusFromProductId(products, orderLine?.ID);
  const imageObjects = canvasJSON?.objects?.filter((o) => o?.type === "image");

  return (
    <>
      <LayersContainer>
        <Header>
          <h3>Layers ({printArea?.printAreaName})</h3>
        </Header>
        <LayersWrapper>
          {canvasJSON?.objects?.map((o, i) => {
            const widthInPixel =
              (Number(o?.width) * o?.scaleX) /
              printArea?.designerSettings?.multiplier;
            const v = printArea?.designerSettings?.width;
            const widthInInches =
              o?.width < 1
                ? 255 / printArea?.designerSettings?.multiplier
                : widthInPixel > v
                ? v
                : widthInPixel;

            const heightInPixel =
              (Number(o?.height) * o?.scaleY) /
              printArea?.designerSettings?.multiplier;
            const vh = printArea?.designerSettings?.height;
            const heightInInches =
              o?.height < 1
                ? 255 / printArea?.designerSettings?.multiplier
                : heightInPixel > vh
                ? vh
                : heightInPixel;

            const widthPercentage = parseInt(
              ((+o?.width / 255) * 100) / +widthInInches
            );

            const isCustomGraphics = o?.src?.includes("/CustomGraphics/");

            return (
              <UpperLayer
                key={`objectsLayer${i}`}
                style={{
                  borderLeftColor:
                    activeObjectIndex === i ? "#000" : "transparent",
                }}
              >
                <Layer
                  onClick={() => {
                    setActiveObjectIndex(i);
                  }}
                  style={{ paddingRight: 10 }}
                >
                  {o?.type === "image" && (
                    <img key={i} src={o?.src} width="60" alt="" />
                  )}

                  {o?.type === "i-text" && (
                    <TextLayer>
                      <TextFieldsIcon />
                    </TextLayer>
                  )}
                  <LayerCenter>
                    <div style={{ display: "flex", alignItems: "center" }}>
                      <Chip label={o?.type === "image" ? "Image" : "Text"} />
                      {o?.type === "i-text" && (
                        <div
                          style={{
                            marginLeft: 10,
                            fontWeight: o?.fontWeight,
                            fontFamily: o?.fontFamily,
                            fontStyle: o?.fontStyle,
                            fontSize: 16,
                            textDecoration: `${
                              o?.underline ? "underline" : ""
                            } ${o?.overline ? "overline" : ""} ${
                              o?.linethrough ? "line-through" : ""
                            }`,
                          }}
                        >
                          {o?.text}
                        </div>
                      )}
                    </div>

                    <div style={{ marginTop: 5, display: "none" }}>
                      <span style={{ marginRight: 10 }}>
                        <b>
                          Print Quality:&nbsp;
                          {o?.type === "i-text" || isCustomGraphics ? (
                            <span style={{ color: "green" }}>Good</span>
                          ) : (
                            <span
                              style={{
                                color:
                                  widthPercentage < 20
                                    ? "red"
                                    : widthPercentage > 20 &&
                                      widthPercentage < 50
                                    ? "#f36c62"
                                    : widthPercentage > 50 &&
                                      widthPercentage < 80
                                    ? "orange"
                                    : "green",
                              }}
                            >
                              {widthPercentage < 20
                                ? "Very Poor"
                                : widthPercentage > 20 && widthPercentage < 50
                                ? "Poor"
                                : widthPercentage > 50 && widthPercentage < 80
                                ? "Average"
                                : "Good"}
                            </span>
                          )}
                        </b>
                      </span>
                    </div>

                    <div style={{ marginTop: 5 }}>
                      <span style={{ marginRight: 10 }}>
                        <b>Width:</b>&nbsp;
                        {widthInInches.toFixed(2)}"
                      </span>
                      <span>
                        <b>Height:</b>&nbsp;
                        {heightInInches.toFixed(2)}"
                      </span>
                    </div>
                  </LayerCenter>
                  <LayerActions>
                    {o?.type === "i-text" && (
                      <Tooltip title="Edit">
                        <EditIcon
                          style={{ color: "#000" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            setEditTextDrawer({ ...o, index: i });
                          }}
                        />
                      </Tooltip>
                    )}
                    {printType !== "EMB" && o?.type !== "image" && (
                      <Tooltip title="Clone">
                        <FileCopyIcon
                          style={{ color: "#000" }}
                          onClick={(e) => {
                            e.stopPropagation();
                            e.preventDefault();
                            const data = {
                              ...canvasJSON,
                              objects: [
                                ...canvasJSON.objects,
                                { ...o, top: o?.top + 10, left: o?.left + 10 },
                              ],
                            };

                            setCanvasJSON(data);
                            onApply(data);
                            setTimeout(() => {
                              setActiveObjectIndex(
                                canvas?.toJSON()?.objects?.length - 1
                              );
                            }, 100);
                          }}
                        />
                      </Tooltip>
                    )}

                    <Tooltip title="Delete">
                      <DeleteIcon
                        style={{ color: "red" }}
                        onClick={(e) => {
                          e.stopPropagation();
                          e.preventDefault();

                          const data = {
                            ...canvasJSON,
                            objects: canvasJSON.objects.filter(
                              (item, j) => j !== i
                            ),
                          };

                          setCanvasJSON(data);
                          onApply(data);
                          setActiveObjectIndex(0);

                          if (o?.type === "image") {
                            const printAreas = orderLine?.printAreas?.map(
                              (p) => {
                                if (!p?.selected) return p;

                                const customGraphicsdData =
                                  p?.customGraphicsdData;

                                return {
                                  ...p,
                                  customGraphicsdData:
                                    customGraphicsdData?.filter(
                                      (c) =>
                                        !o?.src?.includes(
                                          c?.fileName?.split(".")[0]
                                        )
                                    ),
                                };
                              }
                            );
                            setOrderLine({
                              ...orderLine,
                              printAreas,
                            });
                          }
                        }}
                      />
                    </Tooltip>
                  </LayerActions>
                </Layer>
                {activeObjectIndex === i && (
                  <div style={{ marginTop: 5 }}>
                    <h4 style={{ margin: "0 0 5px 0" }}>Align</h4>
                    <AlignIcons>
                      <Tooltip title="Horizontal Align">
                        <FormatAlignCenterIcon
                          onClick={() => {
                            const obj = canvas.getActiveObject();
                            obj.centerH();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", { target: obj });
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Vertical Align">
                        <VerticalAlignCenterIcon
                          onClick={() => {
                            const obj = canvas.getActiveObject();
                            obj.centerV();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", { target: obj });
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Align Top">
                        <VerticalAlignTopIcon
                          onClick={() => {
                            const obj = canvas.getActiveObject();
                            obj.set({ top: 0 });
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", { target: obj });
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Align Bottom">
                        <VerticalAlignBottomIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.set({
                              top:
                                canvas?.height -
                                activeObject.height * activeObject.scaleY,
                            });
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Align Right">
                        <KeyboardTabIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.set({
                              left:
                                canvas.width -
                                activeObject.width * activeObject.scaleX,
                            });
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>
                      <Tooltip title="Align Left">
                        <ArrowBackIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.set({
                              left: 0,
                            });
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Flip Vertical">
                        <SyncAltIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.toggle("flipX");
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>

                      <Tooltip title="Flip Horizontal">
                        <ImportExportIcon
                          onClick={() => {
                            const activeObject = canvas.getActiveObject();
                            activeObject.toggle("flipY");
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", {
                              target: activeObject,
                            });
                          }}
                        />
                      </Tooltip>

                      {imageObjects?.length > 0 && o?.type === "i-text" && (
                        <div>
                          <Tooltip title="Mask">
                            <StarBorderOutlined
                              id={`StarBorderOutlined${i}`}
                              onClick={() => {
                                setMaskIndex(i);
                              }}
                            />
                          </Tooltip>
                          <Popover
                            // id={id}
                            open={maskIndex !== null}
                            anchorEl={document.getElementById(
                              `StarBorderOutlined${i}`
                            )}
                            onClose={() => setMaskIndex(null)}
                            anchorOrigin={{
                              vertical: "bottom",
                              horizontal: "center",
                            }}
                            transformOrigin={{
                              vertical: "top",
                              horizontal: "center",
                            }}
                          >
                            <h4 style={{ margin: "4px 6px" }}>
                              Select Image to Mask
                            </h4>
                            <div
                              style={{
                                display: "flex",
                                padding: "0 10px 10px",
                                gap: 4,
                              }}
                            >
                              {imageObjects?.map((q) => (
                                <img
                                  src={q?.src}
                                  key={q?.src}
                                  alt=""
                                  width="50"
                                  onClick={async () => {
                                    const s = q?.src?.split("/upload/");

                                    let url = "";

                                    if (
                                      q?.src?.includes("e_background_removal")
                                    ) {
                                      url = `${s[0]}/upload/w_${Math.floor(
                                        o?.width
                                      )},h_${Math.floor(o?.height)},${s[1]}`;
                                    } else {
                                      url = `${s[0]}/upload/w_${Math.floor(
                                        o?.width
                                      )},h_${Math.floor(o?.height)}/${s[1]}`;
                                    }

                                    fabric.Image.fromURL(url, function (img) {
                                      // Create the pattern from the image
                                      const pattern = new fabric.Pattern({
                                        source: img.getElement(),
                                        repeat: "repeat",
                                        // offsetX: Math.floor(o?.width / 2),
                                        // offsetY: Math.floor(o?.height / 2),
                                      });

                                      const activeObject =
                                        canvas.getActiveObject();

                                      activeObject.set({
                                        fill: pattern,
                                      });

                                      canvas.requestRenderAll();
                                      const json = canvas?.toJSON();
                                      setCanvasJSON(json);
                                      canvas.fire("object:modified", {
                                        target: activeObject,
                                      });
                                    });
                                  }}
                                  style={{
                                    border: "1px dashed #000",
                                    cursor: "pointer",
                                    padding: 2,
                                    borderRadius: 4,
                                  }}
                                />
                              ))}
                            </div>
                          </Popover>
                        </div>
                      )}
                    </AlignIcons>
                  </div>
                )}

                {o?.type === "image" && (
                  <div style={{ marginRight: 10 }}>
                    <Button
                      title="Remove Background"
                      endIcon={<CropFreeIcon />}
                      containerStyle={{
                        backgroundColor: COLORS.DARK_BLUE,
                        color: COLORS.WHITE,
                        fontWeight: "normal",
                        boxShadow: "4px 4px 20px rgba(0, 0, 0, 0.05)",
                        textTransform: "none",
                        borderRadius: "3px",
                        lineHeight: "1.5",
                        marginTop: "20px",
                        width: "100%",
                      }}
                      onClick={async () => {
                        dispatch(showLoader());

                        const secureUrlSplit = o?.src.split("/upload/");
                        const url = `${secureUrlSplit[0]}/upload/e_background_removal/${secureUrlSplit[1]}`;

                        const image = new Image();

                        image.onload = () => {
                          dispatch(hideLoader());

                          const data = {
                            ...canvasJSON,
                            objects: canvasJSON.objects.map((item, j) => {
                              if (j !== i) {
                                return item;
                              }

                              return { ...item, src: url };
                            }),
                          };

                          setCanvasJSON(data);
                          onApply(data);
                        };

                        image.onerror = () => {
                          dispatch(showLoader());
                          image.src = `${url}?timestamp=${moment().unix()}`;
                        };

                        image.src = url;
                      }}
                    />
                  </div>
                )}

                {o?.type === "image" && dtgPlusOptions?.length > 0 && (
                  <div style={{ marginRight: 10 }}>
                    <h4>Effects</h4>

                    <div style={{ display: "flex", gap: 10 }}>
                      <div style={{ flex: 1 }}>
                        <Select
                          options={dtgPlusOptions}
                          label="Select Effect"
                          value={dtgValues[`dtg_${i}`] || { DisplayName: "" }}
                          valueKey="DisplayName"
                          labelKey="DisplayName"
                          onChange={(dtgProcessName, e) => {
                            setDtgOptions({
                              ...dtgValues,
                              [`dtg_${i}`]: { DisplayName: dtgProcessName },
                            });
                          }}
                        />
                      </div>

                      {dtgPlusOptions?.find(
                        (d) => d?.Name === dtgValues[`dtg_${i}`]?.DisplayName
                      )?.Colors?.length > 0 && (
                        <div style={{ flex: 1 }}>
                          {dtgPlusOptions
                            ?.find(
                              (d) =>
                                d?.Name === dtgValues[`dtg_${i}`]?.DisplayName
                            )
                            ?.Colors?.find(
                              (c) =>
                                c?.ColorName ===
                                dtgValues[`color_${i}`]?.DisplayName
                            ) && (
                            <img
                              alt="color"
                              src={
                                dtgPlusOptions
                                  ?.find(
                                    (d) =>
                                      d?.Name ===
                                      dtgValues[`dtg_${i}`]?.DisplayName
                                  )
                                  ?.Colors?.find(
                                    (c) =>
                                      c?.ColorName ===
                                      dtgValues[`color_${i}`]?.ColorName
                                  )?.ColorImagePath
                              }
                            />
                          )}
                          <Select
                            options={
                              dtgPlusOptions?.find(
                                (d) =>
                                  d?.Name === dtgValues[`dtg_${i}`]?.DisplayName
                              )?.Colors
                            }
                            label="Effect Color"
                            value={dtgValues[`color_${i}`] || { ColorName: "" }}
                            valueKey="ColorName"
                            labelKey="ColorName"
                            onChange={async (dtgColorName) => {
                              setDtgOptions({
                                ...dtgValues,
                                [`color_${i}`]: { ColorName: dtgColorName },
                              });

                              const selectedColor = dtgPlusOptions
                                ?.find(
                                  (d) =>
                                    d?.Name ===
                                    dtgValues[`dtg_${i}`]?.DisplayName
                                )
                                ?.Colors?.find(
                                  (c) => c?.ColorName === dtgColorName
                                );

                              const canvas = document.createElement("canvas");

                              const ctx = canvas.getContext("2d");

                              const secureUrlSplit = o?.src?.split("/upload/");

                              let secureUrl = "";

                              if (!!secureUrlSplit?.length) {
                                secureUrl = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;
                              }

                              dispatch(showLoader());

                              const img1 = await onloadImageProcess(secureUrl);

                              const s =
                                selectedColor?.ColorImagePath?.split(
                                  "/upload/"
                                );

                              const img2 = await onloadImageProcess(
                                `${s[0]}/upload/w_${img1?.width},h_${img1?.height}/${s[1]}`
                              );

                              canvas.width = img1.width;
                              canvas.height = img1.height;

                              ctx.fillStyle = ctx.createPattern(
                                img2,
                                "no-repeat"
                              );
                              // fill canvas with pattern
                              ctx.fillRect(0, 0, canvas.width, canvas.height);
                              // use blending mode multiply
                              ctx.globalCompositeOperation = "multiply";
                              // draw sofa on top
                              ctx.drawImage(
                                img1,
                                0,
                                0,
                                img1.width,
                                img1.height
                              );
                              // change composition mode
                              ctx.globalCompositeOperation = "destination-in";
                              // draw to cut-out sofa
                              ctx.drawImage(
                                img1,
                                0,
                                0,
                                img1.width,
                                img1.height
                                // img1.width * 0.5,
                                // img1.height * 0.5
                              );

                              const dataURL = canvas.toDataURL("image/png");

                              const fileData = new FormData();
                              fileData.append("file", dataURL);
                              fileData.append("upload_preset", "tnedst8q");
                              fileData.append(
                                "folder",
                                `Shirtly/${emailid}/PlusArtFiles`
                              );

                              const data = await fetch(
                                "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload",
                                {
                                  method: "post",
                                  body: fileData,
                                }
                              );
                              dispatch(hideLoader());
                              const json = await data?.json();

                              const data1 = {
                                ...canvasJSON,
                                objects: canvasJSON.objects.map((item, j) => {
                                  if (j !== i) {
                                    return item;
                                  }

                                  return { ...item, src: json?.secure_url };
                                }),
                              };

                              setCanvasJSON(data1);
                              onApply(data1);
                            }}
                          />
                        </div>
                      )}
                    </div>
                  </div>
                )}

                {o?.type === "i-text" && (
                  <div style={{ marginTop: 15, marginRight: 10 }}>
                    <h4 style={{ margin: "0 0 5px 0" }}>Font Size</h4>
                    <Rotate>
                      <Slider
                        defaultValue={o?.fontSize}
                        min={10}
                        max={90}
                        value={o?.fontSize}
                        onChange={(e, v) => {
                          const obj = canvas.getActiveObject();
                          obj.set("fontSize", v);
                          canvas.requestRenderAll();
                          const json = canvas?.toJSON();
                          setCanvasJSON(json);
                          canvas.fire("object:modified", { target: obj });
                        }}
                      />

                      <TextField
                        label="Font Size"
                        min={10}
                        max={90}
                        type="number"
                        size="small"
                        value={o?.fontSize}
                        onChange={(fontSize) => {
                          if (+fontSize <= 90) {
                            const obj = canvas.getActiveObject();
                            obj.set("fontSize", fontSize || 10);
                            canvas.requestRenderAll();
                            const json = canvas?.toJSON();
                            setCanvasJSON(json);
                            canvas.fire("object:modified", { target: obj });
                          }
                        }}
                      />
                    </Rotate>
                  </div>
                )}

                {activeObjectIndex === i && (
                  <div style={{ marginTop: 15, marginRight: 10 }}>
                    <h4 style={{ margin: "0 0 5px 0" }}>Rotate</h4>
                    <Rotate>
                      <Slider
                        defaultValue={10}
                        min={-180}
                        max={180}
                        value={o?.angle}
                        onChange={(e, v) => {
                          const obj = canvas.getActiveObject();
                          obj.rotate(v);
                          canvas.requestRenderAll();
                          const json = canvas?.toJSON();
                          setCanvasJSON(json);
                          canvas.fire("object:modified", { target: obj });
                        }}
                      />

                      <TextField
                        label="Angle"
                        type="number"
                        size="small"
                        value={o?.angle}
                        onChange={(angle) => {
                          const obj = canvas.getActiveObject();
                          obj.rotate(Number(angle));
                          canvas.requestRenderAll();
                          const json = canvas?.toJSON();
                          setCanvasJSON(json);
                          canvas.fire("object:modified", { target: obj });
                        }}
                      />
                    </Rotate>
                  </div>
                )}
              </UpperLayer>
            );
          })}
        </LayersWrapper>
      </LayersContainer>
      {!!editTextDrawer && (
        <EditTextDrawer
          plus={plus}
          open={!!editTextDrawer}
          toggle={() => setEditTextDrawer(false)}
          onDrop={(json) => {
            const data = {
              ...canvasJSON,
              objects: canvasJSON.objects?.map((o, i) => {
                if (i === json?.index) return json;
                return o;
              }),
            };
            setCanvasJSON(data);
            setEditTextDrawer(false);
            onApply(data);
          }}
          data={editTextDrawer}
        />
      )}
    </>
  );
};

export default LayersDrawer;
