import { useState } from "react";
import _ from "lodash";
import { fabric } from "fabric";
import { useDispatch, useSelector } from "react-redux";
import TextFieldsIcon from "@material-ui/icons/TextFields";
import ImageIcon from "@material-ui/icons/Image";
import PaletteIcon from "@material-ui/icons/Palette";
import LayersIcon from "@material-ui/icons/Layers";
import AcUnitIcon from "@material-ui/icons/AcUnit";
import BrowseTextDrawer from "components/ArtWork/BrowseTextDrawer";
import BrowseLibrary from "components/ArtWork/NewBrowseLibrary";
import LayersDrawer from "components/ArtWork/LayersDrawer";
import LogoGeneration from "components/ArtWork/LogoGeneration";
import { showLoader, hideLoader } from "actions/loader";
import { getCustomGraphics } from "actions/designer";
import {
  Container,
  Header,
  HeaderOption,
  Body,
} from "styles/components/ArtWork/artwork-options";

const options = { text: 1, image: 2, colors: 3, layers: 4, logo: 5 };

const ArtworkOptions = ({
  orderLine,
  plus,
  canvas,
  onFabricDrop,
  setOrderLine,
  state,
  setState,
  printType,
  onDrop,
}) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const token = useSelector((state) => state?.auth?.profile?.token);
  const [option, setOption] = useState(options.text);
  const [selectedColorIndex, setColor] = useState(0);

  const handleOnDrop = async (artwork) => {
    let secureUrl = artwork?.secure_url;
    let secureUrlSplit = secureUrl.split("/upload/");
    const API_ENDPOINT =
      "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";

    if (secureUrlSplit?.length === 1) {
      dispatch(showLoader());
      const fileData = new FormData();
      fileData.append("file", secureUrl);
      fileData.append("upload_preset", "tnedst8q");
      fileData.append(
        "folder",
        `Shirtly/${emailid}/CustomGraphics/${artwork?.user}`
      );
      const data = await fetch(API_ENDPOINT, {
        method: "post",
        body: fileData,
      });
      const tempArtwork = await data?.json();

      artwork = { ...artwork, ...tempArtwork };
      dispatch(hideLoader());
    }

    artwork.type = "image";

    new fabric.Image.fromURL(
      artwork?.secure_url,
      function (img) {
        img.set({
          top: 10,
          left: 10,
          lockAspectRatio: true,
          ...artwork,
        });
        const { width: artworkWidth, height: artworkHeight } = artwork;
        const canvasWidth = canvas?.width;
        const canvasHeight = canvas?.height;
        const largeDimension =
          artworkWidth > artworkHeight
            ? "width"
            : artworkWidth < artworkHeight
            ? "height"
            : "width";
        if (artworkHeight > canvasHeight && largeDimension === "height") {
          img.scaleToHeight(canvasHeight - 45);
        }
        if (artworkWidth > canvasWidth && largeDimension === "width") {
          img.scaleToWidth(canvasWidth - 30);
        }
        canvas.add(img);
      },
      {
        crossOrigin: "anonymous",
      }
    );
    onDrop(artwork);
  };

  return (
    <Container>
      <Header>
        <HeaderOption
          id="add-text"
          onClick={() => setOption(options.text)}
          selected={option === options.text}
        >
          <TextFieldsIcon />
          <div className="title">Add Text</div>
        </HeaderOption>

        <HeaderOption
          id="add-image"
          selected={option === options.image}
          onClick={() => setOption(options.image)}
        >
          <ImageIcon />
          <div className="title">Add Image</div>
        </HeaderOption>

        <HeaderOption
          id="add-colors"
          selected={option === options.colors}
          onClick={() => setOption(options.colors)}
        >
          <PaletteIcon />
          <div className="title">Colors</div>
        </HeaderOption>

        <HeaderOption
          id="add-layers"
          selected={option === options.layers}
          onClick={() => setOption(options.layers)}
        >
          <LayersIcon />
          <div className="title">Layers</div>
        </HeaderOption>

        <HeaderOption
          id="add-logo"
          selected={option === options.logo}
          onClick={() => setOption(options.logo)}
        >
          <AcUnitIcon />
          <div className="title">Logo</div>
        </HeaderOption>
      </Header>

      <Body>
        {option === options.text && (
          <BrowseTextDrawer
            orderLine={orderLine}
            plus={plus}
            onDrop={async (artwork) => {
              if (artwork?.text) {
                const text = new fabric.IText(artwork?.text, {
                  fontWeight: artwork?.fontBold ? "bold" : "normal",
                  fontFamily: artwork?.fontFamily,
                  linethrough: artwork?.fontStrikeThrough,
                  underline: artwork?.fontUnderline,
                  overline: artwork?.fontOverline,
                  fontStyle: artwork?.fontItalic ? "italic" : "normal",
                  fontSize: 30,
                });
                text.set({
                  fill: artwork?.color,
                });
                canvas.add(text);
              } else if (artwork?.objects) {
                const json = canvas?.toJSON();
                const customGraphics = artwork?.objects?.filter((j) =>
                  j?.src?.includes("/CustomGraphics/")
                );

                if (!!customGraphics?.length) {
                  const fileNames = customGraphics?.map(
                    (c) => c?.src?.split("/CustomGraphics/")[1]
                  );
                  const customGraphicsdData = await dispatch(
                    getCustomGraphics({ fileNames }, token)
                  );

                  onFabricDrop(
                    {
                      ...json,
                      objects: [...json?.objects, ...artwork?.objects],
                    },
                    customGraphicsdData?.map((q) => ({
                      productionFile: q?.Art_Url,
                      thumbnailUrl: q?.Thumbnail_Url,
                      chargeAmountInUSD: q["Charge_Amount(USD)"],
                      shirtlyChargeAmountInUsd: q["Shirtly_Amount(USD)"],
                      sku: q["Art_SKU"],
                      user: q["user"],
                      fileName: q?.Filename,
                    }))
                  );

                  canvas?.loadFromJSON({
                    ...json,
                    objects: [...json?.objects, ...artwork?.objects],
                  });
                } else {
                  onFabricDrop({
                    ...json,
                    objects: [...json?.objects, ...artwork?.objects],
                  });
                  canvas?.loadFromJSON({
                    ...json,
                    objects: [...json?.objects, ...artwork?.objects],
                  });
                }
              }
            }}
          />
        )}

        {option === options.layers && (
          <LayersDrawer
            canvas={canvas}
            plus={plus}
            orderLine={orderLine}
            setOrderLine={setOrderLine}
            printArea={orderLine?.printAreas.find((p) => p?.selected)}
            onApply={(json) => {
              canvas.clear();
              canvas.loadFromJSON(json);
            }}
          />
        )}

        {option === options.image && (
          <BrowseLibrary
            printType={printType}
            orderLine={orderLine}
            onDrop={async (artwork) => handleOnDrop(artwork)}
          />
        )}

        {option === options.colors && (
          <div
            style={{
              display: "grid",
              gridTemplateColumns: "1fr 1fr 1fr",
              gap: 10,
              overflow: "auto",
              width: "100%",
            }}
          >
            {state?.product?.Colors?.map((color, index) => (
              <div
                key={`Colors${color.ID}`}
                style={{
                  height: "100px",
                  width: "100px",
                  position: "relative",
                  textAlign: "center",
                  cursor: "pointer",
                  border:
                    index === selectedColorIndex ? "1px solid #ccc" : "none",
                }}
              >
                <img
                  alt="color"
                  src={color?.Image}
                  width="88%"
                  //   style={{ width: "100px", height: "100px" }}
                  onClick={() => {
                    setColor(index);
                    setState((p) => ({
                      ...p,
                      artwork: {
                        ...p.artwork,
                        printAreas: p?.artwork?.printAreas?.map((p) => ({
                          ...p,
                          printAreaMarketingImage:
                            p?.printAreaName === "Front"
                              ? color?.Image
                              : color[`${p?.printAreaName}Image`],
                        })),
                      },
                    }));
                  }}
                />
              </div>
            ))}
          </div>
        )}

        {option === options.logo && (
          <LogoGeneration onDrop={async (artwork) => handleOnDrop(artwork)} />
        )}
      </Body>
    </Container>
  );
};

export default ArtworkOptions;
