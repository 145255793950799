import styled from "styled-components";
import DeleteIcon from "@material-ui/icons/Delete";
import CloudDownload from "@material-ui/icons/CloudDownload";
import COLORS from "shared/constants/colors";

const HoverOption = styled.div`
  opacity: 0;
  pointer-events: none;
  transition: all ease-out 0.25s;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: grid;
  align-items: center;
  align-content: center;
  row-gap: 10px;
  > div {
    background-color: ${COLORS.DARK_BLUE};
    color: ${COLORS.WHITE};
    border-radius: 50%;
    border-radius: 37px;
    padding: 5px 15px;
    /* width: 68px; */
    margin: 0 auto;
    cursor: pointer;
  }
`;
const ArtworkThumbnail = styled.div`
  border: ${(props) =>
    props?.verified ? "2px solid green" : "2px solid orange"};
  position: relative;
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  overflow: hidden;
  transition: all ease-out 0.25s;
  transform: scale(1) translate3d(0, 0, 0);
  background: #fff;
  box-shadow: 4px 4px 10px #d9d9d9, -4px -4px 10px #ffffff;

  :hover {
    ${HoverOption} {
      opacity: 1;
      pointer-events: all;
    }
  }
  > div {
    :first-child {
      background: #a8a8b5;
    }
  }
  img {
    padding: 10px;
    width: 100% !important;
    object-fit: scale-down;
    height: 100px;
    transition: all ease-out 0.25s;
    @media (min-width: 992px) {
      min-height: 100%;
      /* min-height: 150px; */
      /* height: 150px; */
      display: block;
    }
    @media screen and (min-width: 1100px) {
      min-height: 100%;
      /* min-height: 120px; */
      /* height: 120px; */
    }
  }
  span {
    background: rgba(21, 22, 22, 0.3);
    padding: 12.5px;
    margin: 0 auto 5px;
    width: auto;
    display: block;
    text-align: center;
    justify-content: center;
    border-radius: 4px;
    font-size: 10px;
    font-weight: 600;
    letter-spacing: 0.5px;
    color: rgba(255, 255, 255, 0.75);
    max-width: 75%;
    text-transform: uppercase;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
  }
`;

const DeleteIconStyled = styled(DeleteIcon)`
  position: absolute;
  z-index: 999;
  top: 0;
  left: 0;
  color: ${COLORS.WHITE};
  cursor: pointer;
`;

const DownloadIconStyled = styled(CloudDownload)`
  position: absolute;
  z-index: 999;
  top: 0;
  right: 0;
  color: ${COLORS.WHITE};
  cursor: pointer;
`;

const Status = styled.div`
  background-color: ${(props) => (props?.verified ? "green" : "orange")};
  color: white;
  font-weight: bold;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  grid-gap: 3px;
`;

export {
  ArtworkThumbnail,
  DeleteIconStyled,
  HoverOption,
  Status,
  DownloadIconStyled,
};
