import React from "react";
import { useDispatch, useSelector } from "react-redux";
import Image from "components/Image";
import { showLoader, hideLoader } from "actions/loader";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import ErrorOutlineIcon from "@material-ui/icons/ErrorOutline";
import { backgroundRemove } from "actions/user";
import { checkImageForBrand } from "actions/aiDesignStudio";

import {
  ArtworkThumbnail,
  DeleteIconStyled,
  HoverOption,
  Status,
  DownloadIconStyled,
} from "styles/components/ArtWork/artworksLibraryItem";

const ArtworksLibraryItem = ({
  libraryFiles = [],
  onDeleteImage,
  onSelect,
  viewOnly = false,
  verify,
  backBurn = false,
  reload,
  // onBackRemove,
}) => {
  const dispatch = useDispatch();
  const token = useSelector((state) => state?.auth?.profile?.token);
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);

  return libraryFiles?.map((l, index) => (
    <ArtworkThumbnail key={l?.asset_id} verified={l.verified}>
      <div>
        {onDeleteImage && !viewOnly && (
          <DeleteIconStyled
            onClick={() => onDeleteImage(l.public_id)}
          ></DeleteIconStyled>
        )}
        <Image
          src={l.secure_url}
          alt={l.asset_id}
          imageStyle={{ objectFit: "contain" }}
        />

        <DownloadIconStyled
          onClick={() => window.open(l.secure_url)}
        ></DownloadIconStyled>

        {/* <span>{l.name}</span> */}
      </div>
      <HoverOption>
        <div
          onClick={async () => {
            // const response = await dispatch(
            //   checkImageForBrand({ url: l.secure_url }, token)
            // );

            // if (response?.imageAnalysisReport?.google?.items?.length > 0) {
            //   alert(
            //     "Your are unable to use selected image as a result of our safety system. The image your are trying to use contains trademark content."
            //   );
            //   return;
            // }

            onSelect(l, index);
          }}
        >
          {verify
            ? l.verified
              ? "Un-Verify"
              : "Verify"
            : viewOnly
            ? "Download"
            : "Select"}
        </div>

        {backBurn && (
          <div
            onClick={async () => {
              const API_ENDPOINT =
                "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";
              const fileData = new FormData();
              fileData.append("file", l?.secure_url);
              fileData.append("upload_preset", "tnedst8q");
              fileData.append("folder", `Shirtly/${emailid}/AIArtFiles`);

              dispatch(showLoader());

              const p = await fetch(API_ENDPOINT, {
                method: "post",
                body: fileData,
              });
              const pjson = await p?.json();

              dispatch(hideLoader());

              const r = await dispatch(
                backgroundRemove({
                  publicId: pjson?.public_id,
                  tags: "fine_edges",
                })
              );
              reload();
            }}
          >
            Back Burn
          </div>
        )}
      </HoverOption>
      <Status verified={l.verified}>
        {l?.verified ? (
          <CheckCircleIcon style={{ fontSize: 16 }} />
        ) : (
          <ErrorOutlineIcon style={{ fontSize: 16 }} />
        )}
        {l.verified ? "Verified" : "Unverified"}
      </Status>
      {/* <Status verified={l.verified} style={{ marginTop: 1 }}>
        {l?.FileName}
      </Status> */}

      {/* <Loader open={loaderVisibility} /> */}
    </ArtworkThumbnail>
  ));
};

export default ArtworksLibraryItem;
