import React, { useState, useEffect } from "react";
import { driver } from "driver.js";
import _ from "lodash";
import { useSelector, useDispatch } from "react-redux";
import { showLoader, hideLoader } from "actions/loader";
import { fabric } from "fabric";
import ToolPrintAreas from "components/ArtWork/ToolPrintAreas";
import PrintableArea from "assets/images/printable-area.jpeg";
import ToolDurasoftFields from "components/ArtWork/ToolDurasoftFields";
import AdvanceToolArtworkBoard from "components/ArtWork/AdvanceToolArtworkBoard";
import ArtWorkAdditionalImages from "components/ArtWork/AdditionalImages";
import ArtWorkErrorMessages from "components/ArtWork/ErrorMessages";
import LayersDrawer from "components/ArtWork/LayersDrawer";
import ArtworkOptions from "components/ArtWork/ArtworkOptions";
import {
  isSub,
  getMarketingImagePathFromProductId,
  isAOP,
} from "selectors/products";
import { default as ImageComponent } from "components/Image";
import {
  Container,
  Wrapper,
  OuterEnvelope,
  InnerEnvelope,
  StyledCloseIcon,
  StyledVisibilityOffIcon,
  PrintableAreaAlign,
  ButtonWrapper,
} from "styles/components/ArtWork";
import {
  OutOfBondWarning,
  OutOfBondRed,
} from "styles/components/ArtWork/browseLibrary";

const onloadImageProcess = (src) =>
  new Promise((resolve, reject) => {
    let img = new Image();
    img.crossOrigin = "Anonymous";
    img.onload = () => resolve(img);
    img.onerror = reject;
    img.src = src;
  });

const ArtWork = ({
  orderLine = {},
  setCoordinates,
  onPreview,
  setOrderLine,
  onFabricDrop,
  onFabricRemove,
  product,
  plus,
  dtgPlusProcess,
  printType,
  isAiGeneratedMockUps,
  colors,
  state,
  setState,
  onDrop,
}) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [canvas, setCanvas] = useState(null);
  const [artworkOutOfBond, setArtworkOutOfBond] = useState(false);
  const [artworkCompleteOutOfBond, setArtworkCompleteOutOfBond] =
    useState(false);
  const [layersDrawer, setLayersDrawer] = useState(false);
  const products = useSelector((state) => state?.products?.data);
  const printAreas = orderLine?.printAreas;
  const selectedPrintArea = printAreas?.find((p) => p?.selected);
  const designerSettings = selectedPrintArea?.designerSettings || {};
  const designerSettingsLength = Object.keys(designerSettings)?.length;
  const isProductAop = isAOP(products, orderLine.ProductID);
  const isProductSub = isSub(products, orderLine?.ProductID);
  const isAiActivated = state?.aiActivated;

  const renderAiMessage = (message) => {
    return `<div style='display:flex;gap:10px;align-items:center;justify-content:center;width:500px;'>
        <div>
          <img src=${state.selectedAiCharaterImage} style='width:100px;' />
        </div>
        <div style='font-size:22px;'>
          ${message}
        </div>
      </div>`;
  };

  const setObjSrc = (obj, url) =>
    new Promise(async (resolve) => {
      obj.setSrc(
        url,
        async () => {
          resolve();
        },
        { crossOrigin: "anonymous" }
      );
    });

  useEffect(() => {
    if (canvas) {
      canvas.dispose();
      setCanvas(undefined);
    }
  }, [plus, dtgPlusProcess, orderLine?.ID]);

  useEffect(() => {
    if (!canvas) {
      const canvas = new fabric.Canvas("canvas", {
        rotationCursor: false,
      });

      canvas.on("object:modified", function () {
        const objects = canvas?.getObjects();
        const isPartiallyOnScreen = objects.filter(
          (o) => o?.isPartiallyOnScreen() && o?.type !== "group"
        );
        const isOnScreen = objects.filter(
          (o) => !o?.isOnScreen() && o?.type !== "group"
        );

        setArtworkCompleteOutOfBond(!!isOnScreen?.length);
        setArtworkOutOfBond(!!isPartiallyOnScreen?.length);

        onFabricDrop({
          ...canvas?.toJSON(),
          outOfBond: !!isOnScreen?.length,
        });
      });

      canvas.on("object:added", async (e) => {
        const obj = e?.target;

        const greyOut = product?.ArtworkSettings?.greyOut;
        const source = _.isFunction(obj?.getSrc) ? obj?.getSrc() : null;
        const text = obj?.text;

        if (obj?.type !== "group") {
          obj.transparentCorners = false;
          obj.cornerColor = "#000";
          obj.cornerStrokeColor = "#fff";
          obj.borderColor = "#000";
          obj.cornerStyle = "circle";
          obj.cornerSize = 10;
          obj.setControlsVisibility({
            tl: true,
            tr: true,
            br: true,
            bl: true,
            ml: false,
            mt: false,
            mr: false,
            mb: false,
            mtr: false,
          });
        } else {
          obj.selectable = false;
          obj.lockMovementX = false;
          obj.lockMovementY = false;
          obj.lockScalingX = false;
          obj.lockScalingY = false;
        }

        if (greyOut) {
          if (source) {
            dispatch(showLoader());
            const secureUrlSplit = source.split("/upload/");
            const url = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:aaa9ad/${secureUrlSplit[1]}`;
            obj._element.src = url;
            await setObjSrc(obj, url);
            canvas.requestRenderAll();
            dispatch(hideLoader());
          } else if (text) {
            obj.fill = "#aaa9ad";
          }
          canvas.requestRenderAll();
        } else if (
          !!plus &&
          !!dtgPlusProcess &&
          !!dtgPlusProcess?.Colors?.find((c) => c?.selected)
        ) {
          const selectedColor = dtgPlusProcess?.Colors?.find(
            (c) => c?.selected
          );

          if (source) {
            dispatch(showLoader());
            const c = document.createElement("canvas", { id: "canvasAtwork" });
            const ctx = c.getContext("2d");
            const secureUrlSplit = source.split("/upload/");
            const url = `${secureUrlSplit[0]}/upload/e_colorize,co_rgb:ffffff/${secureUrlSplit[1]}`;

            const img1 = await onloadImageProcess(url);
            const img2 = await onloadImageProcess(
              selectedColor?.ColorImagePath
            );
            c.width = img1.width;
            c.height = img1.height;
            ctx.fillStyle = ctx.createPattern(img2, "repeat");
            // fill canvas with pattern
            ctx.fillRect(0, 0, c.width, c.height);
            // use blending mode multiply
            ctx.globalCompositeOperation = "multiply";
            // draw sofa on top
            ctx.drawImage(img1, 0, 0, img1.width, img1.height);
            // change composition mode
            ctx.globalCompositeOperation = "destination-in";
            // draw to cut-out sofa
            ctx.drawImage(img1, 0, 0, img1.width, img1.height);
            const dataURL = c.toDataURL("image/png");
            const API_ENDPOINT =
              "https://api.cloudinary.com/v1_1/big-oven-tees-inc/upload";
            const fileData = new FormData();
            fileData.append("file", dataURL);
            fileData.append("upload_preset", "tnedst8q");
            fileData.append("folder", `Shirtly/${emailid}/PlusArtFiles`);

            const data = await fetch(API_ENDPOINT, {
              method: "post",
              body: fileData,
            });

            const json = await data?.json();
            await setObjSrc(obj, json?.secure_url);
            canvas.requestRenderAll();
            dispatch(hideLoader());
          } else if (text) {
            fabric.util.loadImage(selectedColor?.ColorImagePath, (img) => {
              obj.set(
                "fill",
                new fabric.Pattern({
                  source: img,
                  repeat: "no-repeat",
                })
              );

              canvas.requestRenderAll();
            });
          }
        }

        const objs = canvas.getObjects();
        const selectedPrintArea = orderLine?.printAreas?.find(
          (o) => o?.selected
        );

        if (!selectedPrintArea?.previewImage) {
          canvas.setActiveObject(canvas.item(objs?.length - 1));
        }

        const isPartiallyOnScreen = objs.filter(
          (o) => o?.isPartiallyOnScreen() && o?.type !== "group"
        );
        const isOnScreen = objs.filter(
          (o) => !o?.isOnScreen() && o?.type !== "group"
        );

        setArtworkCompleteOutOfBond(!!isOnScreen?.length);
        setArtworkOutOfBond(!!isPartiallyOnScreen?.length);

        onFabricDrop(
          {
            ...canvas?.toJSON(),
            outOfBond: !!isOnScreen?.length,
          },
          obj?.Art_Url
            ? {
                productionFile: obj?.Art_Url,
                thumbnailUrl: obj?.Thumbnail_Url,
                chargeAmountInUSD: obj["Charge_Amount(USD)"],
                shirtlyChargeAmountInUsd: obj["Shirtly_Amount(USD)"],
                sku: obj["Art_SKU"],
                user: obj["user"],
                fileName: obj["FileName"],
              }
            : null
        );

        canvas.requestRenderAll();
      });

      canvas.on("object:removed", function () {
        const objects = canvas?.getObjects();
        const isPartiallyOnScreen = objects.filter(
          (o) => o?.isPartiallyOnScreen() && o?.type !== "group"
        );
        const isOnScreen = objects.filter(
          (o) => !o?.isOnScreen() && o?.type !== "group"
        );

        setArtworkCompleteOutOfBond(!!isOnScreen?.length);
        setArtworkOutOfBond(!!isPartiallyOnScreen?.length);

        onFabricDrop({
          ...canvas?.toJSON(),
          outOfBond: !!isOnScreen?.length,
        });
      });

      const selectedPrintArea = orderLine?.printAreas?.find((o) => o?.selected);

      if (selectedPrintArea?.canvasJSON) {
        canvas.clear();
        canvas.loadFromJSON(selectedPrintArea?.canvasJSON);
      }

      if (canvas) {
        canvas?.setWidth(designerSettings?.width);
        canvas?.setHeight(designerSettings?.height);
      }

      setCanvas(canvas);
      canvas?.requestRenderAll();
    }
  }, [canvas, plus, dtgPlusProcess, designerSettings]);

  useEffect(() => {
    if (orderLine?.aiGenerateImages && canvas) {
      const selctedPrintArea = orderLine?.printAreas?.find((a) => a?.selected);
      const artwork = selctedPrintArea?.aiTempArtwork;

      if (artwork) {
        artwork.type = "image";

        new fabric.Image.fromURL(
          artwork?.secure_url,
          function (img) {
            img.set({
              top: 10,
              left: 10,
              lockAspectRatio: true,
              ...artwork,
            });
            const { width: artworkWidth, height: artworkHeight } = artwork;
            const canvasWidth = canvas?.width;
            const canvasHeight = canvas?.height;
            const largeDimension =
              artworkWidth > artworkHeight
                ? "width"
                : artworkWidth < artworkHeight
                ? "height"
                : "width";
            if (artworkHeight > canvasHeight && largeDimension === "height") {
              img.scaleToHeight(canvasHeight - 45);
            }
            if (artworkWidth > canvasWidth && largeDimension === "width") {
              img.scaleToWidth(canvasWidth - 30);
            }
            canvas.add(img);
          },
          {
            crossOrigin: "anonymous",
          }
        );
      }
    }
  }, [orderLine, canvas]);

  const json = canvas?.toJSON();
  let badQuality = false;

  if (json?.objects?.length > 0) {
    for (let i = 0; i < json?.objects.length; i++) {
      const o = json?.objects[i];
      const widthInPixel =
        (Number(o?.width) * o?.scaleX) /
        selectedPrintArea?.designerSettings?.multiplier;
      const v = selectedPrintArea?.designerSettings?.width;
      const widthInInches =
        o?.width < 1
          ? 255 / selectedPrintArea?.designerSettings?.multiplier
          : widthInPixel > v
          ? v
          : widthInPixel;

      const widthPercentage = parseInt(
        ((+o?.width / 255) * 100) / +widthInInches
      );

      if (o?.type === "image" && widthPercentage < 20) {
        badQuality = true;
        break;
      }
    }
  }

  useEffect(() => {
    if (isAiActivated) {
      const driverObj = driver({
        animate: false,
        showProgress: false,
        allowKeyboardControl: false,

        steps: [
          {
            element: "#mock-up",
            popover: {
              description: renderAiMessage(
                `Generate Mock-up<br/><br/>
                This is where you will adjust your AI created designs on a selected product.<br/><br/>
                You can add text to your design, multiple lines if you want. You can add multiple images and you can also upload an image.<br/><br/>
                There is an option to reduce the text and move it by selecting the text and dragging it where you want. Select again and adjust the text size by moving one of the blue circles to adjust.<br/><br/>
                Once you are happy with your image click on the eye icon and select next.
                `
              ),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },

          {
            element: "#add-text",
            popover: {
              description: renderAiMessage(
                `Add Text<br/><br/>
                In this section, you can add text to your design.
                `
              ),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },
          {
            element: "#add-image",
            popover: {
              description: renderAiMessage(
                `Add Image<br/><br/>
                In this section, you can add images to your design. You can also upload different images from your system and use previously uploaded images from the library.
                `
              ),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },

          {
            element: "#add-colors",
            popover: {
              description: renderAiMessage(
                `Add Colors<br/><br/>
                In this section, you can change garment color of your choice.
                `
              ),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },

          {
            element: "#add-layers",
            popover: {
              description: renderAiMessage(
                `Add Layers<br/><br/>
                In this section, you can edit, align or delete different components or layers from the design.
                `
              ),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },

          {
            element: "#add-logo",
            popover: {
              description: renderAiMessage(
                `Add Logo<br/><br/>
                In this section, you can generate a logo for your company or organisation with the help of AI Model.
                `
              ),
              side: "right",
              align: "start",
              showButtons: ["next"],
            },
          },

          // {
          //   element: "#artwork",
          //   popover: {
          //     description: renderAiMessage(
          //       `You can see the image you saved has been placed on the front of the shirt. Now you have multiple options.
          //       <br/><br/>
          //       1. The red <span style='background-color:red;padding:4px 8px; color :white;border-radius : 50%;font-weight : bold;font-size: 12px;'>X</span> allows you to remove and change the image in the design.
          //       <br/><br/>
          //       2. You can resize the image and move.
          //       <br/><br/>
          //       3. Add text to your design by selecting <span style='text-decoration:underline;'>A</span>`
          //     ),

          //     showButtons: ["next"],
          //   },
          // },

          // {
          //   element: "#right-body-wrapper",
          //   popover: {
          //     description: renderAiMessage(
          //       `Previously saved AI Generated Images<br/><br/>
          //       You can add multiple images to the design area.<br/><br/>
          //       Hover over the image to see more options.<br/><br/>
          //       You can also have the option to remove the background.
          //       `
          //     ),

          //     showButtons: ["next"],
          //   },
          // },

          {
            element: "#next",
            popover: {
              description: renderAiMessage(
                "Congratulations! Your design and mockup are completed.<br/><br/>Select next to order."
              ),

              showButtons: [],
            },
          },
        ],
      });

      driverObj.drive();
    }
  }, []);

  return (
    <Container>
      <ButtonWrapper>
        <ToolDurasoftFields
          orderLine={orderLine}
          setCoordinates={setCoordinates}
        />
      </ButtonWrapper>

      <div
        style={{
          display: "grid",
          gridTemplateColumns: "30% 70%",
          width: "100%",
        }}
      >
        <ArtworkOptions
          orderLine={orderLine}
          plus={plus}
          canvas={canvas}
          onFabricDrop={onFabricDrop}
          colors={colors}
          setOrderLine={setOrderLine}
          state={state}
          setState={setState}
          printType={printType}
          onDrop={onDrop}
        />

        <div style={{ display: "flex", justifyContent: "center" }}>
          <Wrapper id="mock-up" isSub={isProductSub}>
            <ImageComponent
              src={selectedPrintArea?.printAreaMarketingImage}
              alt="ArtWorkImage"
            />

            {!!designerSettingsLength && designerSettings?.width > 0 && (
              <OuterEnvelope
                isSub={isProductSub}
                style={{
                  top: `${designerSettings?.top}px`,
                  left: `${designerSettings?.left}px`,
                  right: `${designerSettings?.right}px`,
                  bottom: `${designerSettings?.bottom}px`,
                  width: `${designerSettings?.width}px`,
                  height: `${designerSettings?.height}px`,
                }}
              >
                {isProductAop ? null : (
                  <InnerEnvelope
                    previewImage={selectedPrintArea?.previewImage}
                    onClick={() => onPreview(false)}
                  >
                    <canvas id="canvas" />
                  </InnerEnvelope>
                )}

                {badQuality && (
                  <OutOfBondWarning>Poor Quality Object</OutOfBondWarning>
                )}

                {artworkOutOfBond && (
                  <OutOfBondWarning>Artwork out of bound</OutOfBondWarning>
                )}

                {artworkCompleteOutOfBond && (
                  <OutOfBondRed>Artwork out of bound</OutOfBondRed>
                )}

                {selectedPrintArea?.canvasJSON &&
                  !!selectedPrintArea?.canvasJSON?.objects?.length &&
                  !selectedPrintArea?.previewImage && (
                    <>
                      <StyledCloseIcon
                        onClick={() => {
                          onFabricRemove();
                          setArtworkOutOfBond(false);
                          setArtworkCompleteOutOfBond(false);
                          canvas.clear();
                        }}
                      />

                      <StyledVisibilityOffIcon
                        onClick={() => {
                          canvas.discardActiveObject().renderAll();
                          onPreview(true);
                        }}
                      />
                      <PrintableAreaAlign>
                        <div>
                          {designerSettings?.platenWidthInch}&nbsp;X&nbsp;
                          {designerSettings?.platenHeightInch}
                          &nbsp;Printable Area
                        </div>
                      </PrintableAreaAlign>
                    </>
                  )}

                {/* <AdvanceToolArtworkBoard
                  onFabricDrop={onFabricDrop}
                  canvas={canvas}
                  orderLine={orderLine}
                  setLayersDrawer={setLayersDrawer}
                  plus={plus}
                  printType={printType}
                /> */}
              </OuterEnvelope>
            )}
          </Wrapper>

          <div
            style={{
              justifyContent: "center",
              display: "flex",
              flexDirection: "column",
              gap: 10,
            }}
          >
            <ToolPrintAreas
              orderLine={orderLine}
              setOrderLine={setOrderLine}
              canvas={canvas}
            />
          </div>
        </div>
      </div>

      {isProductSub && orderLine?.ColorID && orderLine?.ProductID && (
        <img
          src={getMarketingImagePathFromProductId(
            products,
            orderLine?.ProductID,
            orderLine?.ColorID
          )}
          alt="ArtWorkImage"
          style={{
            position: "absolute",
            bottom: 0,
            right: 0,
            width: 100,
          }}
        />
      )}

      {/* {!isAiGeneratedMockUps ? (
        <ArtWorkAdditionalImages orderLine={orderLine} />
      ) : null} */}

      {/* <ArtWorkErrorMessages orderLine={orderLine} /> */}

      {layersDrawer && (
        <LayersDrawer
          canvas={canvas}
          plus={plus}
          orderLine={orderLine}
          setOrderLine={setOrderLine}
          printArea={orderLine?.printAreas.find((p) => p?.selected)}
          open={layersDrawer}
          toggle={() => setLayersDrawer(false)}
          onApply={(json) => {
            canvas.clear();
            canvas.loadFromJSON(json);
          }}
        />
      )}
    </Container>
  );
};

export default ArtWork;
