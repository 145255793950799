import React, { useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import moment from "moment";
import Loader from "components/Loader";
import PropTypes from "prop-types";
import Button from "components/Button";
import COLORS from "shared/constants/colors";
import ErrorMessage from "components/ErrorMessage";
import { checkAIDescriptionForBrand } from "actions/aiDesignStudio";
import {
  Container,
  Header,
  FontPickerWrapper,
} from "styles/components/ArtWork/browseLibrary";

const BrowseTextDrawer = ({ onDrop, plus }) => {
  const dispatch = useDispatch();
  const loaderVisibility = useSelector((state) => state?.loader?.visibility);
  const [trademarkError, setTrademarkError] = useState(false);
  // const [color, setColor] = useState("#000000");
  const [fontFamily, setFontFamily] = useState("Open Sans");
  const [showAllFonts, setShowAllFonts] = useState(false);
  // const [fontSize, setFontSize] = useState(20);
  // const [fontBold, setFontBold] = useState(undefined);
  // const [fontItalic, setFontItalic] = useState(undefined);
  // const [fontUnderline, setFontUnderline] = useState(undefined);
  // const [fontStrikeThrough, setFontStrikeThrough] = useState(undefined);
  // const [fontOverline, setFontOverline] = useState(undefined);
  const googleFonts = useSelector((state) => state?.googleFonts?.data);
  const token = useSelector((state) => state?.auth?.profile?.token);

  const fonts = googleFonts?.items?.filter(
    (d) => d?.version === "v15" || d?.version === "v14"
  );
  // const [inputValue, setInputValue] = React.useState(fontFamily || "");

  return (
    <>
      <Container>
        <Header>
          <h3>Text</h3>
          <h4>Click to add the text</h4>
        </Header>

        {trademarkError && (
          <ErrorMessage>
            Entered text contains some trademark or copy infringement value.
            Please change the text.
          </ErrorMessage>
        )}

        <div style={{ margin: "10px 0" }}>
          <FontPickerWrapper>
            {/* <FontPicker
                sort="alphabet"
                apiKey="AIzaSyCFeviktD35r76PXTlfjR17S3-9HkTjclw"
                activeFontFamily={fontFamily}
                onChange={(nextFont) => setFontFamily(nextFont.family)}
              /> */}

            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 20,
                flexWrap: "wrap",
              }}
            >
              {fonts.map((f, i) => {
                if (i > 20 && !showAllFonts) return null;

                return (
                  <span
                    key={f?.family}
                    onClick={async () => {
                      setFontFamily(f?.family);

                      const tradeMarkInfo = await dispatch(
                        checkAIDescriptionForBrand(
                          { message: f?.family },
                          token
                        )
                      );

                      if (tradeMarkInfo?.tradeMarkWordsCount > 0) {
                        setTrademarkError(true);
                        return;
                      }

                      onDrop({
                        text: f?.family,
                        fontFamily: f?.family,
                        color: "#b2b2b2",
                        public_id: `${moment().millisecond()}${f?.family}`,
                        secure_url: f?.family,
                      });
                    }}
                    style={{
                      fontFamily: f?.family,
                      cursor: "pointer",
                      fontSize: "20px",
                      border:
                        fontFamily === f?.family ? `1px solid black` : "none",
                      padding: fontFamily === f?.family ? "5px" : 0,
                    }}
                  >
                    {f?.family}
                  </span>
                );
              })}
            </div>

            {!showAllFonts && (
              <div style={{ textAlign: "center", padding: "20px 0" }}>
                <Button
                  title="Load more..."
                  onClick={() => setShowAllFonts(true)}
                  containerStyle={{
                    backgroundColor: COLORS.DARK_BLUE,
                    color: COLORS.WHITE,
                    fontWeight: "normal",
                    textTransform: "none",
                    borderRadius: "6px",
                    fontSize: "12px",
                    lineHeight: "1.5",
                    boxShadow: "none",
                  }}
                />
              </div>
            )}
          </FontPickerWrapper>
        </div>
      </Container>
      <Loader open={loaderVisibility} />
    </>
  );
};

BrowseTextDrawer.propTypes = {
  open: PropTypes.bool,
};

export default BrowseTextDrawer;
