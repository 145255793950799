import React, { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import DialogContent from "@material-ui/core/DialogContent";
import AIGeneratedImagesLeft from "components/ArtWork/AIGeneratedImagesLeft";
import AIGeneratedImagesRight from "components/ArtWork/AIGeneratedImagesRight";
import ArtworksLibraryItem from "components/ArtWork/ArtworksLibraryItem";
import { Provider } from "./aiGeneratedImagesContext";
import { getAIDesigns } from "actions/designer";
import SaveAIImagesToLibrary from "./AiDesignPhases/SaveAIImagesToLibrary";
import Robot1 from "assets/images/robot1.png";
import Robot2 from "assets/images/robot2.png";
import {
  Container,
  GetStartedContainer,
} from "styles/components/ArtWork/ai-generated-images";
import {
  WelcomeTitle,
  WelcomeDescription,
  WelcomeFooter,
  WelcomeFooterDesciption,
  WelcomeFooterImage,
  Footer,
  Footer1,
} from "styles/components/ArtWork/ai-generatedimages-welcome";
import { ArtworksLibrary } from "styles/components/ArtWork/browseLibrary";

const AIGeneratedImages = ({ onDrop, toggle }) => {
  const dispatch = useDispatch();
  const emailid = useSelector((state) => state?.auth?.profile?.emailid);
  const [open, setOpen] = useState(false);
  const [designs, setDesigns] = useState();

  const handleClickOpen = () => setOpen(true);

  const handleClose = () => setOpen(false);

  const getDesigns = async () => {
    const aiDesigns = await dispatch(getAIDesigns({ user: emailid }));
    setDesigns({
      ...aiDesigns,
      resources: aiDesigns?.resources?.map((l) => {
        const secureUrl = l.secure_url;
        const splitSecureUrl = secureUrl.split("/");
        if (!!splitSecureUrl?.length) {
          l.name = splitSecureUrl[splitSecureUrl?.length - 1];
        }
        return { ...l };
      }),
    });
  };

  useEffect(() => {
    if (!designs) getDesigns();
  }, [designs]);

  return (
    <>
      {designs?.resources?.length > 0 ? (
        <>
          {/* <Footer style={{ justifyContent: "center" }}>
              <Button
                style={{ fontSize: "12px" }}
                variant="contained"
                color="primary"
                onClick={handleClickOpen}
              >
                Generate Designs with AI Studio
              </Button>
            </Footer> */}
          {designs?.resources?.length > 0 && (
            <ArtworksLibrary>
              <div
                style={{
                  gridTemplateColumns: "repeat(3, 1fr)",
                  rowGap: 10,
                  columnGap: 10,
                }}
              >
                <ArtworksLibraryItem
                  libraryFiles={designs?.resources}
                  backBurn={true}
                  onSelect={(artwork) => {
                    onDrop(artwork);
                    if (toggle) {
                      toggle();
                    }
                  }}
                  reload={() => {
                    setDesigns();
                  }}
                />
              </div>
            </ArtworksLibrary>
          )}
        </>
      ) : null}

      <Dialog open={open} onClose={handleClose} fullScreen>
        <DialogContent style={{ padding: 0 }}>
          <Container>
            <Provider>
              <AIGeneratedImagesLeft />
              <AIGeneratedImagesRight handleClose={handleClose} />
              <SaveAIImagesToLibrary
                reload={() => {
                  handleClose();
                  setDesigns();
                }}
              />
            </Provider>
          </Container>
        </DialogContent>
      </Dialog>
    </>
  );
};

export default AIGeneratedImages;
